
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';


// if (window.screen.width * window.devicePixelRatio >= 3840) {
//   document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }



Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')


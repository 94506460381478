<template>
  <div class="main" id="app">
    <label for="group1">前区：</label>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="text" id="group1" placeholder="输入前区数字，用.相隔" style="width:160px">
    <br>
    <br>
    <label for="group2">后区：</label>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="text" id="group2" placeholder="输入后区数字，用.相隔" style="width:640px">
    <br>
    <br>
    <label for="group6">相同的数字：</label>
    &nbsp;&nbsp;
    <input type="text" id="group3" style="color: red" disabled>
    <br>
    <br>
    <label for="group4">前区数字：</label>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="text" id="group4" disabled>&nbsp;&nbsp;&nbsp;&nbsp;<button @click="copyDifferentNumbers2()">复制</button>
    <br>
    <br>
    <label for="group5">最终结果：</label>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="text" id="group5" disabled style="width:480px">&nbsp;&nbsp;&nbsp;&nbsp;<button @click="copyDifferentNumbers()">复制</button>
    <br>
    <br>
    <label for="group6">最终结果二：</label>
    &nbsp;&nbsp;
    <input type="text" id="group6" style="color: red;width:480px" disabled>&nbsp;&nbsp;&nbsp;&nbsp;<button @click="copyDifferentNumbers3()">复制</button>
    <br>
    <br>
    <button @click="checkGroups()">提交</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button @click="clearFields()">清空</button>

    <br>
    <div id="result"></div>
  </div>
</template>

<script>
  // var redText = '';
  // var redText3 = '';

export default {
  components: {
  },
  data() {
    return {
      redText:'',
      redText3:'',
      redText4:'',
      redText5:''
    };
  },
  mounted() {
    
  },
  methods: {
    copyDifferentNumbers() {
            const differentNumbers = this.redText5;
            // const differentNumbers = this.redText;
            // document.querySelector('span[style="color: red"]').innerText;
            if (differentNumbers) {
                navigator.clipboard.writeText(differentNumbers)
                    .then(() => alert('后区不同的数字已复制'));
            } else {
                alert('后区没有不同的数字可以复制');
            }
        },
    copyDifferentNumbers2() {
            const differentNumbers = this.redText3;
            // document.querySelector('span[style="color: red"]').innerText;
            if (differentNumbers) {
                navigator.clipboard.writeText(differentNumbers)
                    .then(() => alert('前区不同的数字已复制'));
            } else {
                alert('前区没有不同的数字可以复制');
            }
        },
    copyDifferentNumbers3() {
            const differentNumbers = this.redText4;
            // document.querySelector('span[style="color: red"]').innerText;
            if (differentNumbers) {
                navigator.clipboard.writeText(differentNumbers)
                    .then(() => alert('最终结果二的数字已复制'));
            } else {
                alert('最终结果二没有数字可以复制');
            }
        },
    clearFields() {
            document.getElementById('group1').value = '';
            document.getElementById('group2').value = '';
            document.getElementById('group3').value = '';
            document.getElementById('group4').value = '';
            document.getElementById('group5').value = '';
            document.getElementById('group6').value = '';
            document.getElementById('result').innerHTML = '';
            this.redText = '';
            this.redText3 = '';


        },
      checkGroups() {
            const group1 = document.getElementById('group1').value;
            const group2 = document.getElementById('group2').value;

            const numbers1 = new Set(group1.split('.'));
            const numbers2 = new Set(group2.split('.'));

            const resultDiv = document.getElementById('result');
            resultDiv.innerHTML = '';

            const differentNumbers = [];
            const differentNumbers2 = [];
            const differentNumbers2s = [];
            const differentNumbers11 = [];
            const differentNumbers211 = [];

            let number1 = 0;
            // for (const number of numbers2) {
            //   number1++;
            //     if (!numbers1.has(number)) {
            //         differentNumbers.push(number);
            //     }else{
            //         differentNumbers2.push(number);
            //         if(number1 <= 9){
            //             differentNumbers2s.push('0' + number1)
            //         }else{
            //             differentNumbers2s.push(number1)
            //         }
            //     }
            // }



            for (const number of numbers1) {
            //   number1++;
                if (!numbers2.has(number)) {
                    differentNumbers.push(number);
                }else{
                    differentNumbers2.push(number);
                    // if(number1 <= 9){
                    //     differentNumbers2s.push('0' + number1)
                    // }else{
                    //     differentNumbers2s.push(number1)
                    // }
                }
            }



            let arr = document.getElementById('group1').value.split('.');
                let arr1 = document.getElementById('group2').value.split('.');
                arr.forEach((item,index) => {
                    // console.log(arr1.indexOf(item)+1);
                    
                    let i = arr1.indexOf(item);
                    if(i != -1){
                        var sss = i + 1;
                        if(sss < 10){
                            differentNumbers2s.push('0' + sss)
                        }else{
                           
                            differentNumbers2s.push(sss)
                        }
                            // differentNumbers2s.push((i+1)+'')
                        // }else{
                        //     differentNumbers2s.push((i+1)+'')
                        // }
                    }
                });



         for (const number2 of numbers2) {
                if (!numbers1.has(number2)) {
                    differentNumbers11.push(number2);
                }else{
                    differentNumbers211.push(number2);
                }
            }



            const differentNumbers3 = [];
            for (const number of numbers1) {
                // if (!numbers2.has(number)) {
                    differentNumbers3.push(number);
                // }
            }


            this.redText4 = differentNumbers2s.join('.');
            this.redText3 = differentNumbers3.join('.');
            this.redText = differentNumbers.join('.');
            const redText2 = differentNumbers2.join('.');
            this.redText5 = differentNumbers11.join('.');

            if(differentNumbers2[0] == "" && differentNumbers3[0] == ""){
                this.redText4 = "";
            }

            document.getElementById("group5").value = this.redText5;
            // document.getElementById("group5").value = this.redText;
            document.getElementById("group3").value = redText2;
            document.getElementById("group4").value = this.redText3;
            document.getElementById("group6").value = this.redText4;

            // resultDiv.innerHTML = `相同的数字： <span style="color: red">${redText2}</span>`;//后区相同数据
        }
    },
  
};
</script>

<style lang="scss">
</style>
